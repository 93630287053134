import React from "react";
import { getEnvelopeTypeText } from "../lib/utils";
import { numberWithCommas } from "../lib/utils";
import { calculateHowManyDaysTillTargetDate } from "../lib/utils";
import { calculateContributionPerDay } from "../lib/utils";
import PropTypes from "prop-types";
import { Separator } from "./ui/Separator";

const SliceDetailsTable = ({ slice }) => {
  const detailRows = [
    {
      label: "Type",
      value: getEnvelopeTypeText(slice.envelopeType),
    },
    {
      label: "Status",
      value: slice.status ? "Active" : "Inactive",
    },
    {
      label: "Stashed",
      value: `$${numberWithCommas((slice.currentAmount / 100).toFixed(2))}`,
    },
    {
      label: "Target Amount",
      value: `$${numberWithCommas((slice.targetAmount / 100).toFixed(2))}`,
    },
    {
      label: "Remaining Amount",
      value: `$${numberWithCommas(
        (slice.targetAmount / 100 - slice.currentAmount / 100).toFixed(2)
      )}`,
    },
    {
      label: "When you need it by",
      value: `${slice.targetDate} (${calculateHowManyDaysTillTargetDate(
        slice.targetDate
      )} days)`,
    },
    {
      label: "Target Contribution",
      value: `$${calculateContributionPerDay(
        slice.currentAmount,
        slice.targetAmount,
        slice.targetDate
      )} per day`,
    },
    {
      label: "Funding",
      value: `$${calculateContributionPerDay(
        slice.currentAmount,
        slice.targetAmount,
        slice.targetDate
      )} / Payday`,
    },
    {
      label: "Recurring",
      value: slice.isRecurring ? "Yes" : "No",
    },
    ...(slice.isRecurring
      ? [
          {
            label: "Continue After Goal",
            value: slice.continueAfterGoal ? "Yes" : "No",
          },
          {
            label: "Reset Frequency",
            value: slice.resetFrequency,
          },
        ]
      : []),
    {
      label: "Contribution Start Date",
      value: slice.contributionStartDate,
    },
  ];

  return (
    <div className="grid gap-3">
      <div className="font-semibold">Slice Details</div>
      <div className="grid gap-3 rounded-lg border bg-card p-4">
        {detailRows.map((row, index) => (
          <React.Fragment key={row.label}>
            <div className="flex items-center justify-between text-sm">
              <span className="text-muted-foreground">{row.label}</span>
              <span className="font-medium">{row.value}</span>
            </div>
            {index < detailRows.length - 1 && <Separator />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

SliceDetailsTable.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceDetailsTable;
