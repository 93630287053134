import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import DataTable from "./data-table";
import { transactionColumns } from "./data-table/transactionColumns";
import { getSliceOptions } from "../lib/utils";

const RecentTransactionsTable = ({
  transactions,
  refetchTransactions,
  accountOptions,
  categories,
  onRowClick,
  onEditTransaction,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
  pagination,
  onPageChange,
  onPerPageChange,
  isLoading,
  currentFilters,
  onFilterChange,
}) => {
  const handleFilterChange = useCallback(
    async (filterType, values) => {
      onFilterChange(filterType, values);

      await refetchTransactions({
        variables: {
          page: 1,
          perPage: pagination.pageSize,
          accountIds:
            filterType === "accountIds" ? values : currentFilters.accountIds,
          categoryIds:
            filterType === "categoryIds" ? values : currentFilters.categoryIds,
          searchTerm:
            filterType === "searchTerm" ? values : currentFilters.searchTerm,
          sliceIds:
            filterType === "sliceIds" ? values : currentFilters.sliceIds,
        },
      });
    },
    [currentFilters, pagination.pageSize, refetchTransactions, onFilterChange]
  );

  const handleRefreshTransactions = useCallback(async () => {
    try {
      await refetchTransactions({
        variables: {
          page: pagination.currentPage,
          perPage: pagination.pageSize,
          accountIds: currentFilters.accountIds,
          categoryIds: currentFilters.categoryIds,
          searchTerm: currentFilters.searchTerm,
          sliceIds: currentFilters.sliceIds,
        },
      });
    } catch (error) {
      console.error("Error refreshing transactions:", error);
    }
  }, [currentFilters, pagination, refetchTransactions]);

  return (
    <div className="w-full">
      <DataTable
        columns={transactionColumns}
        data={transactions}
        accountOptions={accountOptions}
        sliceOptions={getSliceOptions(slices)}
        refreshTransactions={handleRefreshTransactions}
        showingRecentTransactions={true}
        categories={categories}
        onRowClick={onRowClick}
        onEditTransaction={onEditTransaction}
        onBulkDelete={onBulkDelete}
        onBulkAssign={onBulkAssign}
        onBulkUnassign={onBulkUnassign}
        onBulkChangeCategory={onBulkChangeCategory}
        onBulkChangeDescription={onBulkChangeDescription}
        slices={slices}
        pagination={pagination}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
        currentFilters={currentFilters}
        onFilterChange={handleFilterChange}
        isLoading={isLoading}
        includeUnassigned={true}
      />
    </div>
  );
};

RecentTransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      transactionDate: PropTypes.string.isRequired,
      transactionName: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      category: PropTypes.object,
      assignedSliceName: PropTypes.string,
      assignedSliceType: PropTypes.string,
      accountName: PropTypes.string.isRequired,
    })
  ).isRequired,
  refetchTransactions: PropTypes.func.isRequired,
  setTransactions: PropTypes.func.isRequired,
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditTransaction: PropTypes.func,
  onRowClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  onBulkUnassign: PropTypes.func.isRequired,
  onBulkChangeCategory: PropTypes.func.isRequired,
  onBulkChangeDescription: PropTypes.func.isRequired,
  slices: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  setTotalCount: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  currentFilters: PropTypes.shape({
    accountIds: PropTypes.arrayOf(PropTypes.string),
    sliceIds: PropTypes.arrayOf(PropTypes.string),
    categoryIds: PropTypes.arrayOf(PropTypes.string),
    searchTerm: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default RecentTransactionsTable;
