import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "./ui/Separator";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import DeleteSliceModal from "./DeleteSliceModal";
import { ArrowLeftRightIcon } from "lucide-react";
import {
  calculateContributionPerDay,
  calculateHowManyDaysTillTargetDate,
  calculateSlicePercentage,
  getEnvelopeTypeText,
  numberWithCommas,
  serializeTransactions,
} from "../lib/utils";
import { ProgressBar } from "@tremor/react";
import SliceTransactionsTable from "./SliceTransactionsTable";
import TransactionDetailsView from "./TransactionDetailsView";
import { TransactionForm } from "./EditTransactionForm";
import toast from "react-hot-toast";
import { GET_TRANSACTIONS_FOR_SLICE } from "../queries/transactionQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Spinner } from "./ui/Spinner";
import {
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
} from "../mutations/transactionMutations";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { SliceFormSheet } from "./SliceFormSheet";
import MoveFundsModal from "./MoveFundsModal";
import SliceDetailsTable from "./SliceDetailsTable";
import SliceDetailsHeader from "./SliceDetailsHeader";

const SliceDetails = ({
  slice,
  setSlice,
  sliceOptions,
  categories,
  accounts,
  categoryOptions,
  slices,
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [isSliceFormOpen, setIsSliceFormOpen] = useState(false);
  const [isMoveFundsModalOpen, setIsMoveFundsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    accountIds: [],
    sliceIds: [],
    categoryIds: [],
    searchTerm: "",
  });

  const [getTransactionsForSlice, { loading, error, data, refetch }] =
    useLazyQuery(GET_TRANSACTIONS_FOR_SLICE, {
      variables: {
        sliceId: slice?.id,
        page,
        items: perPage,
        accountIds: filters.accountIds,
        categoryIds: filters.categoryIds,
        searchTerm: filters.searchTerm,
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (slice?.id) {
      getTransactionsForSlice({
        variables: {
          sliceId: slice.id,
          page,
          items: perPage,
          accountIds: filters.accountIds,
          categoryIds: filters.categoryIds,
          searchTerm: filters.searchTerm,
        },
      });
    }
  }, [slice, page, perPage, filters]);

  const [bulkDeleteTransactions] = useMutation(BULK_DELETE_TRANSACTIONS);
  const [bulkAssignTransactionsToSlice] = useMutation(
    BULK_ASSIGN_TRANSACTIONS_TO_SLICE
  );
  const [bulkUnassignTransactionsFromSlice] = useMutation(
    BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE
  );
  const [bulkChangeTransactionCategory] = useMutation(
    BULK_CHANGE_TRANSACTION_CATEGORY
  );
  const [bulkChangeTransactionDescription] = useMutation(
    BULK_CHANGE_TRANSACTION_DESCRIPTION
  );

  useEffect(() => {
    if (data?.getTransactionsForSlice) {
      setTransactions(
        serializeTransactions(
          data.getTransactionsForSlice?.transactions,
          accounts
        )
      );
      setTotalPages(data.getTransactionsForSlice.pagination.totalPages);
      setTotalCount(data.getTransactionsForSlice.pagination.totalCount);
    }
  }, [data, accounts]);

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditing(true);
  };

  const handleBulkDelete = async (transactions) => {
    try {
      const result = await bulkDeleteTransactions({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkDeleteTransactions.success) {
        toast.success(`Deleted ${transactions.length} transactions`);
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkDeleteTransactions.message);
      }
    } catch (error) {
      toast.error("Failed to delete transactions");
    }
  };

  const handleBulkAssign = async (transactions, sliceId) => {
    try {
      const result = await bulkAssignTransactionsToSlice({
        variables: { ids: transactions.map((t) => t.id), sliceId },
      });
      if (result.data.bulkAssignTransactionsToSlice.success) {
        toast.success(`Assigned ${transactions.length} transactions to slice`);
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkAssignTransactionsToSlice.message);
      }
    } catch (error) {
      toast.error("Failed to assign transactions to slice");
    }
  };

  const handleBulkUnassign = async (transactions) => {
    try {
      const result = await bulkUnassignTransactionsFromSlice({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkUnassignTransactionsFromSlice.success) {
        toast.success(
          `Unassigned ${transactions.length} transactions from slice`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkUnassignTransactionsFromSlice.message);
      }
    } catch (error) {
      toast.error("Failed to unassign transactions from slice");
    }
  };

  const handleBulkChangeCategory = async (transactions, categoryId) => {
    try {
      const result = await bulkChangeTransactionCategory({
        variables: { ids: transactions.map((t) => t.id), categoryId },
      });
      if (result.data.bulkChangeTransactionCategory.success) {
        toast.success(
          `Changed category for ${transactions.length} transactions`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkChangeTransactionCategory.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction categories");
    }
  };

  const handleBulkChangeDescription = async (transactions, description) => {
    try {
      const result = await bulkChangeTransactionDescription({
        variables: { ids: transactions.map((t) => t.id), description },
      });
      if (result.data.bulkChangeTransactionDescription.success) {
        toast.success(
          `Changed description for ${transactions.length} transactions`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkChangeTransactionDescription.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction descriptions");
    }
  };

  const handleOnMoveFundsClose = () => {
    setIsMoveFundsModalOpen(false);
    refetch();
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const handleFilterChange = (filterType, values) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: values,
    }));
    setPage(1); // Reset to first page when filters change
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error loading transactions</p>;

  return (
    <div className="h-full">
      {!slice ? (
        <div className="flex-1 flex items-center justify-center h-full">
          <p className="text-lg font-medium text-center">
            Select a slice to view the details
          </p>
        </div>
      ) : (
        <Card className="h-max m-4 mb-4">
          <div className="flex-none">
            <SliceDetailsHeader
              slice={slice}
              onMoveFunds={() => setIsMoveFundsModalOpen(true)}
              onEdit={() => setIsSliceFormOpen(true)}
              setSlice={setSlice}
            />
            <MoveFundsModal
              isOpen={isMoveFundsModalOpen}
              onClose={handleOnMoveFundsClose}
              currentSlice={slice}
              slices={slices}
              accounts={accounts}
            />
            <CardContent className="p-4">
              <SliceDetailsTable slice={slice} />
            </CardContent>
            <Separator />
          </div>
          <CardContent className="p-4">
            <div className="font-semibold mb-3">Transactions</div>
            <SliceTransactionsTable
              slice={slice}
              sliceOptions={sliceOptions || []}
              categories={categories || []}
              accounts={accounts || []}
              categoryOptions={categoryOptions || []}
              onRowClick={handleRowClick}
              onEditTransaction={handleEditTransaction}
              transactions={transactions || []}
              refetch={refetch}
              onBulkDelete={handleBulkDelete}
              onBulkAssign={handleBulkAssign}
              onBulkUnassign={handleBulkUnassign}
              onBulkChangeCategory={handleBulkChangeCategory}
              onBulkChangeDescription={handleBulkChangeDescription}
              slices={slices || []}
              pagination={{
                currentPage: page,
                pageSize: perPage,
                totalPages: totalPages,
                totalCount: totalCount,
              }}
              onPageChange={handlePageChange}
              onPerPageChange={handlePerPageChange}
              onFilterChange={handleFilterChange}
              currentFilters={filters}
              isLoading={loading}
            />
          </CardContent>
        </Card>
      )}
      {selectedTransaction && (
        <TransactionDetailsView
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          onEdit={() => setIsEditing(true)}
          isOpen={!!selectedTransaction && !isEditing}
        />
      )}
      {selectedTransaction && (
        <TransactionForm
          transaction={selectedTransaction}
          categories={categories}
          slices={slices}
          onClose={() => {
            setIsEditing(false);
            setSelectedTransaction(null);
            refetch();
          }}
          isOpen={!!selectedTransaction && isEditing}
        />
      )}

      <SliceFormSheet
        isOpen={isSliceFormOpen}
        onClose={() => setIsSliceFormOpen(false)}
        setSelectedSlice={setSlice}
        isEditMode={true}
        selectedSlice={slice}
        accounts={accounts}
      />
    </div>
  );
};

SliceDetails.propTypes = {
  slice: PropTypes.object,
  setSlice: PropTypes.func,
  sliceOptions: PropTypes.array,
  categories: PropTypes.array,
  accounts: PropTypes.array,
  categoryOptions: PropTypes.array,
  slices: PropTypes.array,
  setSliceFormMode: PropTypes.func,
  setIsSliceFormOpen: PropTypes.func,
  isSliceFormOpen: PropTypes.bool,
};

export default SliceDetails;
