import { ColumnDef, TableMeta } from "@tanstack/react-table";
import React from "react";
import { Transaction } from "../../lib/transactionSchema";
import { Checkbox } from "../ui/Checkbox";
import DataTableColumnHeader from "./DataTableColumnHeader";
import DataTableRowActions from "./DataTableRowActions";
import { Badge } from "../ui/Badge";
import {
  cn,
  formatCurrency,
  formatTransactionAmount,
  numberWithCommas,
} from "../../lib/utils";

interface ExtendedTableMeta extends TableMeta<Transaction> {
  refreshTransactions?: () => void;
  onEditTransaction?: (transaction: Transaction) => void;
}

export const sliceTransactionColumns: ColumnDef<
  Transaction,
  ExtendedTableMeta
>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => {
          table.toggleAllPageRowsSelected(!!value);
        }}
        aria-label="Select all rows"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "transactionDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({ row }) => (
      <div className="w-[100px] font-medium">
        {row.getValue("transactionDate")}
      </div>
    ),
  },
  {
    accessorKey: "transactionName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Description" />
    ),
    cell: ({ row }) => {
      const transaction = row.original;
      const transactionName = transaction.transactionName;
      const merchantLogoUrl = transaction.merchantLogoUrl;
      const status = transaction.status;
      return (
        <div className="w-[250px] font-medium flex items-center space-x-2">
          {merchantLogoUrl ? (
            <img
              src={merchantLogoUrl}
              alt="Merchant Logo"
              className="h-6 w-auto"
            />
          ) : null}
          <span style={status === "pending" ? { fontStyle: "italic" } : {}}>
            {transactionName}
          </span>
          {status === "pending" ? (
            <Badge variant="outline">Pending</Badge>
          ) : null}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => {
      const transaction = row.original;
      const formatted = formatTransactionAmount(transaction);
      return <div className="w-[100px] font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "category",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Category" />
    ),
    cell: ({ row }) => {
      const category = row.getValue("category") as { id: string; name: string };
      return (
        <div className="w-[120px] font-medium">
          {category && typeof category === "object" && "name" in category
            ? category.name
            : "Uncategorized"}
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      const category = row.getValue(id) as { id: string; name: string };
      return value.includes(category?.name || "Uncategorized");
    },
  },
  {
    accessorKey: "assignedSliceName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Assigned Slice" />
    ),
    cell: ({ row }) => {
      const transaction = row.original;
      const sliceName = transaction.assignedSliceName;
      return (
        <div className="w-[200px] font-medium">
          {sliceName ? (
            <Badge
              className={cn("ml-auto")}
              variant={
                transaction.assignedSliceType as
                  | "default"
                  | "secondary"
                  | "destructive"
                  | "outline"
                  | "expense_type"
                  | "protected_type"
                  | "goal_type"
                  | "debt_type"
                  | null
                  | undefined
              }
            >
              {sliceName}
            </Badge>
          ) : (
            <Badge variant="outline">Unassigned</Badge>
          )}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "balanceBefore",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Balance Before" />
    ),
    cell: ({ row }) =>
      row.original.balanceBeforeTransaction
        ? `$${numberWithCommas(
            (row.original.balanceBeforeTransaction / 100).toFixed(2)
          )}`
        : "$0.00",
  },
  {
    accessorKey: "balanceAfter",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Balance After" />
    ),
    cell: ({ row }) =>
      row.original.balanceAfterTransaction
        ? `$${numberWithCommas(
            (row.original.balanceAfterTransaction / 100).toFixed(2)
          )}`
        : "$0.00",
  },
  {
    accessorKey: "accountName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    cell: ({ row }) => (
      <div className="w-[180px] font-medium">{row.getValue("accountName")}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    cell: ({ row, table }) => (
      <DataTableRowActions
        row={row}
        table={{
          ...table,
          options: {
            ...table.options,
            meta: table.options.meta as ExtendedTableMeta,
          },
        }}
      />
    ),
  },
];
