import { gql } from "@apollo/client";

export const MOVE_FUNDS = gql`
  mutation MoveFunds(
    $sourceType: String!
    $sourceId: ID!
    $destinationType: String!
    $destinationId: ID!
    $amount: Float!
  ) {
    moveFunds(
      input: {
        sourceType: $sourceType
        sourceId: $sourceId
        destinationType: $destinationType
        destinationId: $destinationId
        amount: $amount
      }
    ) {
      success
      errors
    }
  }
`;
