import { gql } from "@apollo/client";

const VALIDATE_SESSION_QUERY = gql`
  query ValidateSession {
    validateSession {
      valid
    }
  }
`;

export { VALIDATE_SESSION_QUERY };
