import React, { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "./ui/Dialog";
import { SliceForm } from "./SliceForm";
import { Button } from "./ui/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import { PencilIcon } from "lucide-react";
import { cn } from "../lib/utils";
import { SliceFormSheet } from "./SliceFormSheet";

const CreateSliceModal = ({ setSelectedSlice, selectedSlice, accounts }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isEditMode = selectedSlice !== null;

  if (isDesktop) {
    return (
      <SliceFormSheet
        setOpen={setOpen}
        setSelectedSlice={setSelectedSlice}
        isEditMode={isEditMode}
        selectedSlice={selectedSlice}
        accounts={accounts}
      />
    );
  }
};

CreateSliceModal.propTypes = {
  setSelectedSlice: PropTypes.func,
  selectedSlice: PropTypes.object,
  accounts: PropTypes.array,
};

export default CreateSliceModal;
