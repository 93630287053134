import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";

interface ButtonProps {
  className?: string;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link";
  size?: "default" | "xs" | "sm" | "lg" | "icon";
  asChild?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors duration-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-gray-300",
  {
    variants: {
      variant: {
        default:
          "bg-gray-900 text-gray-50 shadow hover:bg-opacity-70 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-opacity-90 h-10",
        destructive:
          "bg-red-500 text-gray-50 shadow hover:bg-opacity-70 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-opacity-90 h-10",
        outline:
          "border border-gray-400 bg-transparent shadow-sm hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-50 h-10",
        secondary:
          "bg-gray-300 text-gray-900 shadow-sm hover:bg-opacity-80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-opacity-80 h-10",
        ghost:
          "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50 h-10",
        link: "text-gray-900 underline-offset-4 hover:underline dark:text-gray-50 h-10",
      },
      size: {
        default: "h-8 px-4 py-2",
        xs: "h-7 rounded-md px-2.5 text-xs",
        sm: "h-8 rounded-md px-2.5 text-sm",
        lg: "h-10 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = ({
  className,
  variant,
  size,
  asChild = false,
  children,
  disabled = false,
  ...props
}) => {
  const Comp = asChild ? Slot : "button";
  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
      disabled={disabled}
    >
      {children}
    </Comp>
  );
};
Button.displayName = "Button";

export { Button, buttonVariants };
