import React, { useEffect, useState } from "react";
import { Row, Table, TableMeta } from "@tanstack/react-table";
import { Transaction, transactionSchema } from "../../lib/transactionSchema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../ui/Dropdown";
import { Button } from "../ui/Button";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SLICES_FOR_USER } from "../../queries/sliceQueries";
import {
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
} from "../../mutations/transactionMutations";
import { toast } from "react-hot-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { TransactionForm } from "../EditTransactionForm";
import { GET_CATEGORIES } from "../../queries/categoryQueries";
import DeleteTransactionModal from "../ConfirmationModal";
import TransactionDetailsView from "../TransactionDetailsView";
import ConfirmationModal from "../ConfirmationModal";

interface ExtendedTableMeta<TData> extends TableMeta<TData> {
  refreshTransactions?: () => void;
  onEditTransaction?: (transaction: Transaction) => void;
  onRowClick?: (transaction: Transaction) => void;
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  table: Table<TData> & { options: { meta?: ExtendedTableMeta<TData> } };
}

function DataTableRowActions<TData>({
  row,
  table,
}: DataTableRowActionsProps<TData>) {
  const transaction = transactionSchema.parse(row.original);
  const refreshTransactions = table.options.meta?.refreshTransactions;
  const onEditTransaction = table.options.meta?.onEditTransaction;
  const [sliceId, setSliceId] = useState(transaction.envelopeId);
  const [slices, setSlices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useQuery(GET_SLICES_FOR_USER, {
    onCompleted: (data) => {
      setSlices(data.slicesForUser);
    },
    fetchPolicy: "cache-and-network",
  });

  useQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      setCategories(data.categories);
    },
    fetchPolicy: "cache-and-network",
  });

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const [deleteTransaction] = useMutation(DELETE_TRANSACTION);

  const sliceOptions = slices.map((slice: { name: string; id: string }) => {
    return {
      label: slice.name,
      value: slice.id,
    };
  });

  const handleAssignTransactionToSlice = (sliceId: string) => {
    updateTransaction({
      variables: {
        id: transaction.id,
        envelopeId: sliceId,
      },
    })
      .then(() => {
        displayUpdateTransactionSuccess(sliceId);
        if (typeof refreshTransactions === "function") {
          console.log("Calling refreshTransactions");
          refreshTransactions();
        } else {
          console.error(
            "refreshTransactions is not a function or is undefined"
          );
        }
      })
      .catch((error) => {
        displayUpdateTransactionError(error);
        console.error("Error updating transaction:", error);
      });
  };

  const displayUpdateTransactionError = (error) => {
    toast.error(error.message);
  };

  const displayUpdateTransactionSuccess = (sliceId: string) => {
    const message = sliceId
      ? "Transaction assigned to slice!"
      : "Transaction unassigned from slice!";
    toast.success(message);
  };

  const handleDeleteTransaction = () => {
    deleteTransaction({
      variables: {
        id: transaction.id,
      },
    })
      .then(() => {
        toast.success("Transaction deleted successfully");
        if (typeof refreshTransactions === "function") {
          refreshTransactions();
        }
        setIsDeleteModalOpen(false);
      })
      .catch((error) => {
        toast.error("Failed to delete transaction");
        console.error("Error deleting transaction:", error);
      });
  };

  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open Menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onSelect={(e) => {
              e.preventDefault();
              e.stopPropagation();
              table.options.meta?.onRowClick?.(transaction);
            }}
          >
            View Details
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onEditTransaction) {
                console.log("onEditTransaction called", transaction);
                table.options.meta?.onEditTransaction?.(transaction);
              } else {
                console.log("onEditTransaction is not a function");
              }
            }}
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {transaction.assignedSliceName ? (
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleAssignTransactionToSlice("");
                setSliceId("");
              }}
            >
              Unassign from Slice
            </DropdownMenuItem>
          ) : null}
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Assign To Slice</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup
                value={sliceId ?? undefined}
                onValueChange={(value) => {
                  setSliceId(value);
                }}
              >
                {sliceOptions.map((slice) => (
                  <DropdownMenuRadioItem
                    value={slice.value}
                    key={slice.value}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAssignTransactionToSlice(slice.value);
                    }}
                  >
                    {slice.label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDeleteModalOpen(false);
        }}
        onConfirm={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDeleteTransaction();
        }}
        title="Delete Transaction"
        description={`Are you sure you want to delete the transaction "${transaction.transactionName}"? This action cannot be undone.`}
        confirmText="Delete"
      />
    </div>
  );
}

export default DataTableRowActions;
