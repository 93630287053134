import React, { useMemo } from "react";
import { ChartContainer, ChartTooltip, type ChartConfig } from "./Chart";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { COLORS } from "../../lib/utils";

interface SpendingBreakdownChartProps {
  data: {
    category: { id: string; name: string };
    amount: number;
  }[];
}

const SpendingBreakdownChart: React.FC<SpendingBreakdownChartProps> = ({
  data,
}) => {
  const chartConfig: ChartConfig = useMemo(() => {
    return data.reduce((config, item, index) => {
      config[item.category.id] = {
        label: item.category.name,
        color: COLORS[index % COLORS.length],
      };
      return config;
    }, {} as ChartConfig);
  }, [data]);

  const totalAmount = data.reduce(
    (sum, item) => sum + Math.abs(item.amount),
    0
  );

  const formattedData = useMemo(() => {
    return data.map((item, index) => ({
      ...item,
      value: item.amount,
      id: item.category.id,
      color: COLORS[index % COLORS.length],
    }));
  }, [data]);

  return (
    <ChartContainer className="w-full h-full" config={chartConfig}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={formattedData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius="80%"
            innerRadius="40%"
            fill="#8884d8"
            dataKey="value"
          >
            {formattedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <ChartTooltip
            content={({ payload }) => {
              if (payload && payload.length) {
                const data = payload[0].payload;
                return (
                  <div className="rounded-lg bg-white p-2 shadow-md text-xs sm:text-sm">
                    <div className="font-medium">{data.category.name}</div>
                    <div className="text-gray-500">
                      ${(data.amount / 100).toFixed(2)}
                    </div>
                    <div className="text-gray-500">
                      {((data.amount / totalAmount) * 100).toFixed(1)}%
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default SpendingBreakdownChart;
