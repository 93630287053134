import { React } from "react";
import { CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import { ArrowLeftRightIcon } from "lucide-react";
import DeleteSliceModal from "./DeleteSliceModal";
import { Badge } from "./ui/Badge";
import PropTypes from "prop-types";
import { Pencil1Icon } from "@radix-ui/react-icons";
const SliceDetailsHeader = ({ slice, onMoveFunds, onEdit, setSlice }) => {
  return (
    <CardHeader className="flex flex-row items-start p-4 bg-gray-100/50">
      <div className="grid gap-1 w-1/2">
        <CardTitle className="flex flex-row items-center gap-2">
          <span className="text-lg">{slice.name}</span>
          <div className="flex flex-row items-center gap-2">
            {slice.status ? (
              <Badge variant="success">On Track</Badge>
            ) : (
              <Badge variant="destructive">Off Track</Badge>
            )}
          </div>
        </CardTitle>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-muted-foreground">{slice.notes}</div>
          <div className="text-md font-semibold">
            Due: {new Date(slice.targetDate).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div className="ml-auto flex items-center gap-1">
        <Button variant="outline" onClick={onMoveFunds}>
          <ArrowLeftRightIcon className="w-4 h-4 mr-2" />
          <span className="text-md lg:sr-only xl:not-sr-only xl:whitespace-nowrap">
            Move Funds
          </span>
        </Button>
        <Button variant="outline" onClick={onEdit}>
          <Pencil1Icon className="w-4 h-4 mr-2" />
          Edit
        </Button>
        <DeleteSliceModal
          selectedSlice={slice}
          setSelectedSlice={setSlice}
          sliceName={slice.name}
        />
      </div>
    </CardHeader>
  );
};

SliceDetailsHeader.propTypes = {
  slice: PropTypes.object.isRequired,
  onMoveFunds: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  setSlice: PropTypes.func.isRequired,
};

export default SliceDetailsHeader;
