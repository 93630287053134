import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/Dialog";
import { Button } from "./ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import { useMutation, useQuery } from "@apollo/client";
import { MOVE_FUNDS } from "../mutations/moveFundsMutations";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import { GET_SAFE_TO_SPEND_BREAKDOWN } from "../queries/transactionQueries";
import { Separator } from "./ui/Separator";
import { CircleDollarSign } from "lucide-react";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { useSafeToSpend } from "./SafeToSpend";

const formSchema = z.object({
  sourceType: z.enum(["safe_to_spend", "slice"]),
  sourceId: z.string().optional(),
  destinationType: z.enum(["safe_to_spend", "slice"]),
  destinationId: z.string().optional(),
  amount: z.string().min(0.01, "Amount is required"),
});

const MoveFundsModal = ({ isOpen, onClose, currentSlice, onSuccess }) => {
  const { data: slicesData } = useQuery(GET_SLICES_FOR_USER);
  const { data: safeToSpendData, refetch: refetchSafeToSpend } = useQuery(
    GET_SAFE_TO_SPEND_BREAKDOWN
  );

  const [moveFunds] = useMutation(MOVE_FUNDS);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sourceType: "safe_to_spend",
      sourceId: "",
      destinationType: "slice",
      destinationId: currentSlice?.id || "",
      amount: "",
    },
  });

  const sourceType = form.watch("sourceType");
  const destinationType = form.watch("destinationType");

  useEffect(() => {
    if (sourceType === "safe_to_spend") {
      form.setValue("destinationType", "slice");
    } else if (sourceType === "slice" && destinationType === "slice") {
      // Allow slice to slice, do nothing
    } else {
      form.setValue("destinationType", "safe_to_spend");
    }
  }, [sourceType, form]);

  const onSubmit = async (values) => {
    try {
      const { data } = await moveFunds({
        variables: {
          ...values,
          amount: values.amount * 100,
        },
      });
      if (data.moveFunds.success) {
        toast.success("Funds moved successfully");
        // Refetch safe to spend data if affected
        if (
          values.sourceType === "safe_to_spend" ||
          values.destinationType === "safe_to_spend"
        ) {
          refetchSafeToSpend();
        }
        form.reset();
        onSuccess?.();
        onClose();
      } else {
        toast.error(data.moveFunds.errors[0]);
      }
    } catch (error) {
      toast.error("Failed to move funds");
    }
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex flex-row items-center">
            <CircleDollarSign className="w-6 h-6 mr-2" />
            <span className="text-md lg:sr-only xl:not-sr-only xl:whitespace-nowrap">
              Move Funds
            </span>
          </DialogTitle>
        </DialogHeader>
        <Separator orientation="horizontal" />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="sourceType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select source" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="safe_to_spend">
                        Safe to Spend ($
                        {(
                          safeToSpendData?.safeToSpendBreakdownForUser
                            .safeToSpend / 100
                        ).toFixed(2)}
                        )
                      </SelectItem>
                      <SelectItem value="slice">Slice</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />

            {sourceType === "slice" && (
              <FormField
                control={form.control}
                name="sourceId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Source Slice</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select slice" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {slicesData?.slicesForUser?.map((slice) => (
                          <SelectItem key={slice.id} value={slice.id}>
                            {slice.name} ($
                            {(slice.currentAmount / 100).toFixed(2)})
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="destinationType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Destination</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select destination" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="safe_to_spend">
                        Safe to Spend ($
                        {(
                          safeToSpendData?.safeToSpendBreakdownForUser
                            .safeToSpend / 100
                        ).toFixed(2)}
                        )
                      </SelectItem>
                      <SelectItem value="slice">Slice</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />

            {destinationType === "slice" && (
              <FormField
                control={form.control}
                name="destinationId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Destination Slice</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select slice" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {slicesData?.slicesForUser?.map((slice) => (
                          <SelectItem key={slice.id} value={slice.id}>
                            {slice.name} ($
                            {(slice.currentAmount / 100).toFixed(2)})
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      onValueChange={(value, name, values) => {
                        // const numericValue = values.float;
                        // console.log(numericValue);
                        console.log(values);
                        console.log(name);
                        console.log(value);
                        // form.setValue(
                        //   "amount",
                        //   isNaN(numericValue) ? 0 : numericValue
                        // );
                        field.onChange(value);
                      }}
                      placeholder="$0.00"
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      className="flex h-9 w-full rounded-md border border-gray-400 bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:ring-pink-vivid-500 disabled:cursor-not-allowed disabled:opacity-50"
                      prefix="$"
                      value={field.value}
                      allowDecimals={true}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator orientation="horizontal" />
            <div className="flex justify-between">
              <Button type="button" variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Move Funds</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

MoveFundsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentSlice: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default MoveFundsModal;
