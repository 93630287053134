import { gql } from "@apollo/client";

export const UPDATE_SAFE_TO_SPEND_ACCOUNTS = gql`
  mutation UpdateSafeToSpendAccounts($accountIds: [ID!]!, $included: Boolean!) {
    updateSafeToSpendAccounts(
      input: { accountIds: $accountIds, included: $included }
    ) {
      user {
        id
      }
      safeToSpendBreakdown {
        availableBalance
        safeToSpend
        envelopeBalances
      }
      errors
    }
  }
`;
