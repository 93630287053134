import React from "react";
import { Column } from "@tanstack/react-table";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { Button } from "../ui/Button";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Separator } from "../ui/Separator";
import { Badge } from "../ui/Badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../ui/Command";
import { cn } from "../../lib/utils";

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    id: string;
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
    count?: number;
  }[];
  onFilterChange?: (filterType: string, values: any[]) => void;
  currentValues: any[];
  filterType: "accountIds" | "sliceIds" | "categoryIds";
}

function DataTableFacetedFilter<TData, TValue>({
  column,
  title,
  options = [],
  onFilterChange,
  currentValues,
  filterType,
}: DataTableFacetedFilterProps<TData, TValue>) {
  const facets = column?.getFacetedUniqueValues();
  const updatedFacets = new Map<string, number>();

  facets?.forEach((count, value) => {
    if (typeof value === "object" && value !== null && "name" in value) {
      updatedFacets.set(
        value.name,
        (updatedFacets.get(value.name) || 0) + count
      );
    } else if (value === null || value === "") {
      updatedFacets.set(
        "Unassigned",
        (updatedFacets.get("Unassigned") || 0) + count
      );
    } else {
      const valueStr = value?.toString() || "Unassigned";
      updatedFacets.set(valueStr, (updatedFacets.get(valueStr) || 0) + count);
    }
  });

  const selectedValues = new Set(currentValues);

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.id))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.id}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {Array.isArray(options) &&
                options?.map((option) => {
                  const isSelected = selectedValues.has(option.id);
                  return (
                    <CommandItem
                      key={option.id}
                      onSelect={() => {
                        const newValues = isSelected
                          ? currentValues.filter((v) => v !== option.id)
                          : [...currentValues, option.id];
                        console.log("newValues", newValues);
                        onFilterChange?.(filterType, newValues);
                        column?.setFilterValue(newValues);
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected
                            ? "bg-primary text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon className={cn("h-4 w-4")} />
                      </div>
                      {option?.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      <span>{option.label}</span>
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {option.count || 0}
                      </span>
                    </CommandItem>
                  );
                })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => {
                      onFilterChange?.(filterType, []);
                      column?.setFilterValue(undefined);
                    }}
                    className="justify-center text-center"
                  >
                    Clear Filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default DataTableFacetedFilter;
