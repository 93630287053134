import React, { useMemo } from "react";
import {
  ChartContainer,
  ChartLegend,
  ChartTooltip,
  type ChartConfig,
} from "./Chart";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

interface IncomeVsExpensesChartProps {
  data: {
    date: string;
    amount: number;
    income: number;
    expenses: number;
  }[];
}

const chartConfig: ChartConfig = {
  income: {
    label: "Income",
    // color: "#A0D8DA",
    color: "#228B22",
  },
  expenses: {
    label: "Expenses",
    // color: "#2A37FF",
    color: "#FF6961",
  },
};

const IncomeVsExpensesChart: React.FC<IncomeVsExpensesChartProps> = ({
  data,
}) => {
  const formattedData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      income: item.income / 100,
      expenses: item.expenses / 100,
    }));
  }, [data]);

  return (
    <ChartContainer
      className="h-[300px] sm:h-[400px] w-full"
      config={chartConfig}
    >
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={formattedData}>
          <defs>
            <linearGradient id="incomeGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={chartConfig.income.color}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={chartConfig.income.color}
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient id="expensesGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={chartConfig.expenses.color}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={chartConfig.expenses.color}
                stopOpacity={0.2}
              />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(value) => {
              const date = new Date(value);
              return date.toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
              });
            }}
            tick={{ fontSize: 10 }}
            interval="preserveStartEnd"
            minTickGap={20}
          />
          <YAxis
            label={{
              value: "Amount",
              position: "insideLeft",
              angle: -90,
              dy: 10,
              fontSize: 12,
            }}
            tick={{ fontSize: 10 }}
          />
          <ChartTooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="rounded-lg bg-white p-2 shadow-md text-xs sm:text-sm">
                    <div className="font-medium">
                      {new Date(payload[0].payload.date).toLocaleDateString(
                        "en-US",
                        { month: "short", day: "numeric" }
                      )}
                    </div>
                    <div className="text-gray-500 flex items-center">
                      <div className="w-2 h-2 sm:w-3 sm:h-3 mr-1 sm:mr-2 bg-[#228B22]"></div>
                      Income: $
                      {typeof payload[0].value === "number"
                        ? payload[0].value.toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="text-gray-500 flex items-center">
                      <div className="w-2 h-2 sm:w-3 sm:h-3 mr-1 sm:mr-2 bg-[#FF6961]"></div>
                      Expenses: $
                      {typeof payload[1].value === "number"
                        ? payload[1].value.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <ChartLegend />
          <Area
            type="monotone"
            dataKey="income"
            stackId="1"
            stroke={chartConfig.income.color}
            fill="url(#incomeGradient)"
          />
          <Area
            type="monotone"
            dataKey="expenses"
            stackId="1"
            stroke={chartConfig.expenses.color}
            fill="url(#expensesGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default IncomeVsExpensesChart;
