// app/javascript/components/AutoContributionCalculator.jsx
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CALCULATE_AUTO_CONTRIBUTION } from "../mutations/sliceMutations";
import PropTypes from "prop-types";
import { Badge } from "./ui/Badge";

const AutoContributionCalculator = ({
  targetAmount = 0,
  currentAmount = 0,
  targetDate,
  interval,
  onCalculated,
}) => {
  const [calculateContribution, { loading, data }] = useMutation(
    CALCULATE_AUTO_CONTRIBUTION
  );
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    if (targetAmount && targetDate && interval) {
      calculateContribution({
        variables: {
          targetAmount: targetAmount * 100,
          currentAmount: currentAmount * 100,
          targetDate,
          interval,
        },
      });
    }
  }, [targetAmount, currentAmount, targetDate, interval]);

  useEffect(() => {
    if (data && data.calculateAutoContribution) {
      setAmount(data.calculateAutoContribution.amount);
      onCalculated(data.calculateAutoContribution.amount);
    }
  }, [data]);

  useEffect(() => {
    console.log("amount", amount);
  }, [amount]);

  return (
    <div className="flex flex-row gap-2 items-center justify-center w-full">
      <div className="flex flex-row gap-4 w-full">
        <p className="w-2/3 text-sm text-gray-500">
          Suggested contribution amount:
        </p>
        {loading ? (
          <p>Calculating...</p>
        ) : amount ? (
          <span className="w-1/3 font-bold">
            ${(amount / 100).toFixed(2)} {interval}
          </span>
        ) : (
          <p>Please fill in all required fields</p>
        )}
      </div>
    </div>
  );
};

AutoContributionCalculator.propTypes = {
  targetAmount: PropTypes.number,
  currentAmount: PropTypes.number,
  targetDate: PropTypes.string,
  interval: PropTypes.string,
  onCalculated: PropTypes.func,
};

export default AutoContributionCalculator;
