import { gql } from "@apollo/client";

export const GET_SAFE_TO_SPEND_BREAKDOWN = gql`
  query GetSafeToSpendBreakdown {
    safeToSpendBreakdownForUser {
      availableBalance
      safeToSpend
      envelopeBalances
    }
  }
`;

export const GET_USER_ACCOUNTS = gql`
  query GetUserAccounts {
    accountsForUser {
      id
      name
      includedInSafeToSpend
      availableBalance
    }
  }
`;
