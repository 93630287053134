import React from "react";
import { Link, useLocation } from "react-router-dom";
import { defaultNavItems } from "./defaultNavItems";
import { NavigationLink } from "./NavigationLink";
import PropTypes from "prop-types";
import { Separator } from "../ui/Separator";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { formatCurrency } from "../../lib/utils";
import { Spinner } from "../ui/Spinner";
import { useSafeToSpend } from "../SafeToSpend";

const MenuContent = ({ setCurrentNavItem }) => {
  const location = useLocation();
  const { breakdownData, breakdownLoading } = useSafeToSpend();

  const safeToSpendBreakdown = breakdownData?.safeToSpendBreakdownForUser || {
    availableBalance: 0,
    expenseBalance: 0,
    protectedBalance: 0,
    debtBalance: 0,
    goalBalance: 0,
    safeToSpend: 0,
  };

  if (breakdownLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex w-full flex-col text-sm">
      <Link
        to="/"
        onClick={() => setCurrentNavItem("overview")}
        className="py-4"
      >
        <span className="text-lg font-semibold tracking-tight">
          Cake Budget
        </span>
      </Link>

      <div className="flex flex-col">
        {location.pathname !== "/" && (
          <div className="flex flex-col my-2 gap-x-2">
            <Separator orientation="horizontal" />
            <Popover>
              <PopoverTrigger className="flex flex-col py-2">
                <span className="font-bold text-lg">Safe To Spend</span>
                <span className="font-medium text-sm">
                  {formatCurrency(safeToSpendBreakdown.safeToSpend)}
                </span>
              </PopoverTrigger>
              <PopoverContent className="w-[300px] p-4" align="end">
                <div className="space-y-4">
                  <div className="flex flex-col space-y-1.5">
                    <span className="text-sm text-muted-foreground">
                      Available Balance
                    </span>
                    <span className="text-2xl font-bold">
                      {formatCurrency(safeToSpendBreakdown.availableBalance)}
                    </span>
                  </div>
                  <Separator />
                  <div className="space-y-3">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Expenses</span>
                      <span className="text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown?.envelopeBalances
                            ?.expense_type || 0
                        )}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Goals</span>
                      <span className="text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances.goal_type || 0
                        )}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Protected</span>
                      <span className="text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances
                            .protected_type || 0
                        )}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Debt</span>
                      <span className="text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances.debt_type || 0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        )}
        <Separator orientation="horizontal" />
        <nav className="flex flex-col mt-4 mb-4">
          <ul role="list" className="flex flex-1 flex-col">
            <li>
              <ul role="list" className="-mx-2 space-y-2">
                {defaultNavItems.map((navItem, index) => (
                  <li key={index}>
                    <NavigationLink
                      href={navItem.href}
                      label={navItem.label}
                      icon={navItem.icon}
                      onClick={() => {
                        setCurrentNavItem(navItem.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <hr className="mt-auto" />
    </div>
  );
};

MenuContent.propTypes = {
  setCurrentNavItem: PropTypes.func.isRequired,
  safeToSpendData: PropTypes.object,
  safeToSpendLoading: PropTypes.bool,
  refetchSafeToSpend: PropTypes.func,
};

export default MenuContent;
