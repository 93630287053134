import { gql } from "@apollo/client";

const CREATE_SLICE = gql`
  mutation createSliceForUser(
    $name: String!
    $envelopeType: EnvelopeTypeEnum!
    $targetAmount: Float!
    $currentAmount: Float
    $notes: String
    $targetDate: ISO8601Date!
    $color: String
    $status: Boolean!
    $autoContribute: Boolean
    $contributionInterval: String
    $contributionAmount: Int
    $sourceAccountId: ID
    $isRecurring: Boolean
    $continueAfterGoal: Boolean
    $resetFrequency: String
    $contributionStartDate: ISO8601Date
  ) {
    createSlice(
      input: {
        name: $name
        envelopeType: $envelopeType
        targetAmount: $targetAmount
        currentAmount: $currentAmount
        notes: $notes
        targetDate: $targetDate
        color: $color
        status: $status
        autoContribute: $autoContribute
        contributionInterval: $contributionInterval
        contributionAmount: $contributionAmount
        sourceAccountId: $sourceAccountId
        isRecurring: $isRecurring
        continueAfterGoal: $continueAfterGoal
        resetFrequency: $resetFrequency
        contributionStartDate: $contributionStartDate
      }
    ) {
      envelope {
        id
        name
        envelopeType
        targetAmount
        currentAmount
        notes
        color
        status
        targetDate
        isRecurring
        continueAfterGoal
        resetFrequency
        contributionStartDate
        rules {
          id
          name
          ruleCategory
          active
          ruleConditions {
            id
            field
            operator
            value
          }
          ruleActions {
            id
            actionType
            parameters
          }
          ruleSchedule {
            id
            interval
            nextRun
          }
        }
        autoContribution {
          id
          envelopeId
          sourceAccountId
          amount
          nextContributionDate
          interval
        }
      }
      errors
    }
  }
`;

const DELETE_SLICE = gql`
  mutation deleteSliceForUser($id: ID!) {
    deleteSlice(input: { id: $id }) {
      success
      errors
    }
  }
`;

const UPDATE_SLICE = gql`
  mutation updateSliceForUser(
    $id: ID!
    $name: String!
    $envelopeType: EnvelopeTypeEnum!
    $targetAmount: Float!
    $currentAmount: Float
    $notes: String
    $targetDate: ISO8601Date!
    $color: String
    $status: Boolean!
    $autoContribute: Boolean
    $contributionInterval: String
    $contributionAmount: Int
    $sourceAccountId: ID
    $isRecurring: Boolean!
    $continueAfterGoal: Boolean
    $resetFrequency: String
    $contributionStartDate: ISO8601Date
  ) {
    updateSlice(
      input: {
        id: $id
        name: $name
        envelopeType: $envelopeType
        targetAmount: $targetAmount
        currentAmount: $currentAmount
        notes: $notes
        targetDate: $targetDate
        color: $color
        status: $status
        autoContribute: $autoContribute
        contributionInterval: $contributionInterval
        contributionAmount: $contributionAmount
        sourceAccountId: $sourceAccountId
        isRecurring: $isRecurring
        continueAfterGoal: $continueAfterGoal
        resetFrequency: $resetFrequency
        contributionStartDate: $contributionStartDate
      }
    ) {
      envelope {
        id
        name
        envelopeType
        targetAmount
        currentAmount
        notes
        color
        status
        targetDate
        isRecurring
        continueAfterGoal
        resetFrequency
        contributionStartDate
        rules {
          id
          name
          ruleCategory
          active
          ruleConditions {
            id
            field
            operator
            value
          }
          ruleActions {
            id
            actionType
            parameters
          }
          ruleSchedule {
            id
            interval
            nextRun
          }
        }
        autoContribution {
          id
          envelopeId
          sourceAccountId
          amount
          nextContributionDate
          interval
        }
      }
      errors
    }
  }
`;

const CALCULATE_AUTO_CONTRIBUTION = gql`
  mutation calculateAutoContribution(
    $targetAmount: Float!
    $currentAmount: Float!
    $targetDate: ISO8601Date!
    $interval: String!
  ) {
    calculateAutoContribution(
      input: {
        targetAmount: $targetAmount
        currentAmount: $currentAmount
        targetDate: $targetDate
        interval: $interval
      }
    ) {
      amount
    }
  }
`;

export {
  CREATE_SLICE,
  DELETE_SLICE,
  UPDATE_SLICE,
  CALCULATE_AUTO_CONTRIBUTION,
};
