import React from "react";
import { Transaction } from "../lib/transactionSchema";
import { formatCurrency, formatDate } from "../lib/utils";
import { Badge } from "./ui/Badge";
import { Separator } from "./ui/Separator";
import * as LucideIcons from "lucide-react";
import { Button } from "./ui/Button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "./ui/Sheet";

interface TransactionDetailsViewProps {
  transaction: Transaction;
  onClose: () => void;
  onEdit: () => void;
  isOpen: boolean;
}

const TransactionDetailsView: React.FC<TransactionDetailsViewProps> = ({
  transaction,
  onClose,
  onEdit,
  isOpen,
}) => {
  const IconComponent =
    transaction.category?.icon &&
    LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      ? LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      : LucideIcons.HelpCircle;

  const detailRows = [
    { label: "Date", value: formatDate(transaction.transactionDate) },
    {
      label: "Status",
      value: (
        <div className="mt-4">
          {transaction.status === "pending" ? (
            <span className="italic">Pending</span>
          ) : (
            "Posted"
          )}
        </div>
      ),
    },
    { label: "Amount", value: formatCurrency(transaction.amount) },
    { label: "Category", value: transaction.category?.name || "Uncategorized" },
    { label: "Account", value: transaction.accountName },
    {
      label: "Merchant",
      value: (
        <div className="flex items-center space-x-2">
          {transaction.merchantLogoUrl && (
            <img
              src={transaction.merchantLogoUrl}
              alt="Merchant Logo"
              className="h-6 w-auto"
            />
          )}
          <span>{transaction.merchantName || "N/A"}</span>
        </div>
      ),
    },
    {
      label: "Assigned Slice",
      value: transaction.assignedSliceName ? (
        <Badge variant={transaction.assignedSliceType as any}>
          {transaction.assignedSliceName}
        </Badge>
      ) : (
        <Badge variant="outline">Unassigned</Badge>
      ),
    },
    {
      label: "Notes",
      value: transaction.notes ? transaction.notes : "No notes",
    },
  ];

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Transaction Details</SheetTitle>
        </SheetHeader>
        <div className="flex flex-row items-center justify-between p-6 bg-gray-100">
          <div className="flex items-center space-x-4">
            <IconComponent className="w-10 h-10 text-gray-600" />
            <div>
              <h2 className="text-xl font-bold text-gray-800">
                {transaction.transactionName}
              </h2>
            </div>
          </div>
          <span className="text-xl font-bold text-gray-800">
            {formatCurrency(transaction.amount)}
          </span>
        </div>
        <div className="p-6">
          <div className="grid gap-4">
            <h3 className="font-semibold text-lg text-gray-700">Details</h3>
            <div className="grid gap-2">
              {detailRows.map((row, index) => (
                <React.Fragment key={index}>
                  <div className="flex items-center justify-between py-2">
                    <span className="text-gray-600">{row.label}</span>
                    <span className="font-medium text-gray-800">
                      {row.value}
                    </span>
                  </div>
                  {index < detailRows.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <SheetFooter>
          <Button onClick={onClose} variant="outline">
            Close
          </Button>
          <Button onClick={onEdit} variant="default">
            Edit
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default TransactionDetailsView;
