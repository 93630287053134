import { gql } from "@apollo/client";

const USER_SIGN_IN = gql`
  mutation UserSignIn($email: String!, $password: String!) {
    userSignIn(input: { email: $email, password: $password }) {
      user {
        id
        email
        trialOrPremium
      }
      errors
    }
  }
`;

const USER_SIGN_UP = gql`
  mutation UserSignUp(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    userSignUp(
      input: {
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      user {
        id
        email
        trialOrPremium
      }
      errors
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      success
      errors
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    deleteAccount {
      success
      errors
    }
  }
`;

export { USER_SIGN_IN, USER_SIGN_UP, CHANGE_PASSWORD, DELETE_ACCOUNT };
