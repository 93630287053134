import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_RULE, UPDATE_RULE } from "../mutations/ruleMutations";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Select } from "./ui/Select";
import { Switch } from "./ui/Switch";
import PropTypes from "prop-types";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/Card";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "./ui/Sheet";
import { getFieldType } from "../lib/utils";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import { GET_ACCOUNTS_FOR_USER } from "../queries/plaidQueries";

const formSchema = z.object({
  name: z.string().min(3, { message: "Name must be at least 3 characters" }),
  ruleCategory: z.string({ required_error: "Please select a category." }),
  active: z.boolean(),
  conditions: z.array(
    z.object({
      field: z.string(),
      operator: z.string(),
      value: z.string(),
      startDate: z.string().optional(),
      endDate: z.string().optional(),
    })
  ),
  actions: z.array(
    z.object({
      actionType: z.string(),
      parameters: z.object({
        slice_id: z.string().optional(),
        category_id: z.string().optional(),
        amount: z.number().optional(),
      }),
    })
  ),
  schedule: z.object({
    interval: z.string(),
    nextRun: z.string().refine((date) => !isNaN(Date.parse(date)), {
      message: "Invalid date format",
    }),
  }),
});

const RuleForm = ({ rule, onComplete, onClose, isOpen, isEditMode }) => {
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const getDefaultValues = () => {
    if (isEditMode) {
      return {
        name: rule?.name,
        ruleCategory: rule?.ruleCategory,
        active: rule?.active,
        conditions: rule?.ruleConditions || [],
        actions: rule?.ruleActions || [],
        schedule: rule?.ruleSchedule,
      };
    } else {
      return {
        name: "",
        ruleCategory: "",
        active: true,
        conditions: [],
        actions: [],
        schedule: {
          interval: "daily",
          nextRun: new Date().toISOString().split("T")[0],
        },
      };
    }
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  const {
    fields: conditionFormFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const {
    fields: actionFields,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    control: form.control,
    name: "actions",
  });

  const [createRule, { loading: createLoading }] = useMutation(CREATE_RULE);
  const [updateRule, { loading: updateLoading }] = useMutation(UPDATE_RULE);
  const { data: categoriesData, loading: categoriesLoading } =
    useQuery(GET_CATEGORIES);
  const { data: slicesData, loading: slicesLoading } =
    useQuery(GET_SLICES_FOR_USER);
  const { data: accountsData, loading: accountsLoading } = useQuery(
    GET_ACCOUNTS_FOR_USER
  );

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      const variables = {
        ...data,
        ruleCategory: data.ruleCategory,
        conditions: data.conditions.map((condition) => {
          if (condition.field === "date_range") {
            return {
              field: condition.field,
              operator: "between",
              value: JSON.stringify({
                startDate: condition.startDate,
                endDate: condition.endDate,
              }),
            };
          }
          return {
            ...condition,
            operator: condition.operator,
          };
        }),
        actions: data.actions.map((action) => {
          if (action.actionType === "allocate_to_slices") {
            return {
              ...action,
              parameters: {
                allocations: action.parameters.allocations.filter(
                  (allocation) => allocation && allocation.percentage > 0
                ),
              },
            };
          }
          return action;
        }),
      };

      if (data.schedule && data.schedule.interval) {
        variables.schedule = {
          interval: data.schedule.interval,
          nextRun: data.schedule.nextRun, // Convert to YYYY-MM-DD string
        };
      }

      if (rule) {
        await updateRule({ variables: { id: rule.id, ...variables } });
      } else {
        await createRule({ variables });
      }
      onComplete();
    } catch (error) {
      console.error("Error saving rule:", error);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      form.setValue("name", rule.name);
      form.setValue("ruleCategory", rule.ruleCategory);
      setIsCategorySelected(true);
      form.setValue("active", rule.active);
      form.setValue("conditions", rule.ruleConditions);
      form.setValue("actions", rule.ruleActions);
      form.setValue("schedule", rule.ruleSchedule);
    }
  }, [isEditMode]);

  const handleClose = () => {
    form.reset({
      name: "",
      ruleCategory: "",
      active: true,
      conditions: [],
      actions: [],
      schedule: { interval: "", nextRun: new Date() },
    });
    setIsCategorySelected(false);
    onClose();
  };

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === "ruleCategory") {
        setIsCategorySelected(!!value.ruleCategory);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const ruleCategories = [
    { value: "transaction_rule", label: "Transaction Rule" },
    // { value: "funding_rule", label: "Funding Rule" },
    // { value: "balance_rule", label: "Balance Rule" },
    // { value: "date_rule", label: "Date-based Rule" },
  ];

  const conditionFields = {
    transaction_rule: [
      { value: "merchant_name", label: "Merchant Name" },
      { value: "amount", label: "Amount" },
      { value: "category", label: "Category" },
      { value: "description", label: "Description" },
    ],
    funding_rule: [
      { value: "deposit_amount", label: "Deposit Amount" },
      { value: "account", label: "Account" },
    ],
    balance_rule: [
      { value: "slice_balance", label: "Slice Balance" },
      { value: "safe_to_spend_balance", label: "Safe to Spend Balance" },
    ],
    date_rule: [{ value: "date_range", label: "Date Range" }],
  };

  const operators = {
    string: [
      { value: "contains", label: "Contains" },
      { value: "eq", label: "Equals" },
    ],
    number: [
      { value: "eq", label: "Equals" },
      { value: "gt", label: "Greater Than" },
      { value: "gte", label: "Greater Than or Equal" },
      { value: "lt", label: "Less Than" },
      { value: "lte", label: "Less Than or Equal" },
    ],
    date_range: [{ value: "between", label: "Between" }],
    dropdown: [{ value: "eq", label: "Equals" }],
  };

  const actionTypes = {
    transaction_rule: [
      { value: "assign_to_slice", label: "Assign to Slice" },
      { value: "assign_category", label: "Assign Category" },
      { value: "round_up", label: "Round Up" },
    ],
    funding_rule: [
      { value: "allocate_to_slices", label: "Allocate to Slices" },
    ],
    balance_rule: [
      { value: "transfer_between_slices", label: "Transfer Between Slices" },
      {
        value: "transfer_to_safe_to_spend",
        label: "Transfer to Safe to Spend",
      },
    ],
    date_rule: [
      { value: "assign_transactions", label: "Assign Transactions to Slice" },
      { value: "assign_categories", label: "Assign Transactions to Category" },
      { value: "transfer_funds", label: "Transfer Funds" },
    ],
  };

  const renderAllocationForm = (index) => {
    return (
      <div>
        <FormField
          control={form.control}
          name={`actions.${index}.parameters.allocations`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slice Allocations</FormLabel>
              <FormControl>
                <div>
                  {slicesData?.slicesForUser.map((slice) => (
                    <div
                      key={slice.id}
                      className="flex items-center space-x-2 mb-2"
                    >
                      <Input
                        type="number"
                        placeholder={slice.name}
                        value={
                          field.value?.find(
                            (allocation) => allocation.slice_id === slice.id
                          )?.percentage || ""
                        }
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value) || 0;
                          const newAllocations = [...(field.value || [])];
                          const existingIndex = newAllocations.findIndex(
                            (allocation) => allocation.slice_id === slice.id
                          );
                          if (existingIndex !== -1) {
                            newAllocations[existingIndex].percentage = newValue;
                          } else {
                            newAllocations.push({
                              slice_id: slice.id,
                              percentage: newValue,
                            });
                          }
                          field.onChange(newAllocations);
                        }}
                      />
                      <span>%</span>
                    </div>
                  ))}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    );
  };

  const renderActionParameters = (actionType, index) => {
    if (!actionType) return null; // Return null if no action type is selected

    switch (actionType) {
      case "assign_to_slice":
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters.slice_id`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Slice" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[250px] overflow-y-auto">
                      {slicesData &&
                        slicesData.slicesForUser.map((slice) => (
                          <SelectItem key={slice.id} value={slice.id}>
                            {slice.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "assign_category":
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters.category_id`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select category" />
                    </SelectTrigger>
                    <SelectContent>
                      {categoriesData &&
                        categoriesData.categories.map((category) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "round_up":
        return (
          <>
            <FormField
              control={form.control}
              name={`actions.${index}.parameters.roundUpType`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select round up type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="nearest_dollar">
                          To nearest dollar
                        </SelectItem>
                        {/* <SelectItem value="specific_amount">
                          Specific amount
                        </SelectItem> */}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            {form.watch(`actions.${index}.parameters.roundUpType`) ===
              "specific_amount" && (
              <FormField
                control={form.control}
                name={`actions.${index}.parameters.amount`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="Amount"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            )}
          </>
        );
      case "assign_transactions":
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters.slice_id`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a slice" />
                    </SelectTrigger>
                    <SelectContent>
                      {slicesData?.slicesForUser.map((slice) => (
                        <SelectItem key={slice.id} value={slice.id}>
                          {slice.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "assign_categories":
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters.category_id`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a category" />
                    </SelectTrigger>
                    <SelectContent>
                      {categoriesData?.categories.map((category) => (
                        <SelectItem key={category.id} value={category.id}>
                          {category.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "transfer_funds":
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters.amount`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Amount" />
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "allocate_to_slices":
        return renderAllocationForm(index);
      default:
        return (
          <FormField
            control={form.control}
            name={`actions.${index}.parameters`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Parameters" />
                </FormControl>
              </FormItem>
            )}
          />
        );
    }
  };

  const renderConditionParameters = (field, index) => {
    if (!field) return null; // Return null if no condition field is selected

    switch (getFieldType(field)) {
      case "number":
        return (
          <FormField
            control={form.control}
            name={`conditions.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} type="number" placeholder="Value" />
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "date":
        return (
          <FormField
            control={form.control}
            name={`conditions.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} type="date" placeholder="Value" />
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "dropdown":
        return (
          <FormField
            control={form.control}
            name={`conditions.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={`Select ${conditionFields[form.watch("ruleCategory")][index].label}`}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {(() => {
                        switch (
                          conditionFields[form.watch("ruleCategory")][index]
                            .value
                        ) {
                          case "category":
                            return categoriesData?.categories.map(
                              (category) => (
                                <SelectItem
                                  key={category.id}
                                  value={category.id}
                                >
                                  {category.name}
                                </SelectItem>
                              )
                            );
                          case "slices":
                            return slicesData?.slicesForUser.map((slice) => (
                              <SelectItem key={slice.id} value={slice.id}>
                                {slice.name}
                              </SelectItem>
                            ));
                          case "account":
                            return accountsData?.accountsForUser.map(
                              (account) => (
                                <SelectItem key={account.id} value={account.id}>
                                  {account.name}
                                </SelectItem>
                              )
                            );
                          default:
                            return null;
                        }
                      })()}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        );
      case "date_range":
        return (
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name={`conditions.${index}.startDate`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      {...field}
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`conditions.${index}.endDate`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>End Date</FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      {...field}
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        );
      default:
        return (
          <FormField
            control={form.control}
            name={`conditions.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Value" />
                </FormControl>
              </FormItem>
            )}
          />
        );
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent side="right" className="w-1/2 overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{rule ? "Edit Rule" : "Create Rule"}</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Gas Slice Rule" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ruleCategory"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Category</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      form.setValue("conditions", []);
                      form.setValue("actions", []);
                    }}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {ruleCategories.map((category) => (
                        <SelectItem key={category.value} value={category.value}>
                          {category.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Active</FormLabel>
                    <FormDescription>
                      Enable or disable this rule
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Card>
              <CardHeader>
                <CardTitle>Conditions</CardTitle>
                <CardDescription>
                  Adding multiple conditions is an implicit AND
                </CardDescription>
              </CardHeader>
              <CardContent>
                {Array.isArray(conditionFormFields) &&
                  conditionFormFields.map((field, index) => (
                    <div
                      key={field.id}
                      className="flex space-x-2 mb-2 items-center"
                    >
                      <FormField
                        control={form.control}
                        name={`conditions.${index}.field`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Select
                                onValueChange={(value) => {
                                  field.onChange(value);
                                  form.setValue(
                                    `conditions.${index}.value`,
                                    ""
                                  ); // Reset value when field changes
                                }}
                                defaultValue={field.value}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select field" />
                                </SelectTrigger>
                                <SelectContent>
                                  {conditionFields[
                                    form.watch("ruleCategory")
                                  ]?.map((option) => (
                                    <SelectItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name={`conditions.${index}.operator`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Operator" />
                                </SelectTrigger>
                                <SelectContent>
                                  {operators[
                                    getFieldType(
                                      form.watch(`conditions.${index}.field`)
                                    )
                                  ]?.map((option) => (
                                    <SelectItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {renderConditionParameters(
                        form.watch(`conditions.${index}.field`),
                        index
                      )}
                      <Button
                        type="button"
                        onClick={() => removeCondition(index)}
                        variant="destructive"
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                <Button
                  type="button"
                  onClick={() =>
                    appendCondition({ field: "", operator: "", value: "" })
                  }
                  disabled={!isCategorySelected}
                >
                  Add Condition
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Actions</CardTitle>
                <CardDescription>
                  Adding multiple actions is an implicit AND
                </CardDescription>
              </CardHeader>
              <CardContent>
                {Array.isArray(actionFields) &&
                  actionFields.map((field, index) => (
                    <div
                      key={field.id}
                      className="flex space-x-2 mb-2 items-center"
                    >
                      <FormField
                        control={form.control}
                        name={`actions.${index}.actionType`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Select
                                onValueChange={(value) => {
                                  field.onChange(value);
                                  form.setValue(
                                    `actions.${index}.parameters`,
                                    {}
                                  ); // Reset parameters when action type changes
                                }}
                                defaultValue={field.value}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Action Type" />
                                </SelectTrigger>
                                <SelectContent>
                                  {actionTypes[form.watch("ruleCategory")]?.map(
                                    (option) => (
                                      <SelectItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </SelectItem>
                                    )
                                  )}
                                </SelectContent>
                              </Select>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {renderActionParameters(
                        form.watch(`actions.${index}.actionType`),
                        index
                      )}
                      <Button
                        type="button"
                        onClick={() => removeAction(index)}
                        variant="destructive"
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                <Button
                  type="button"
                  onClick={() =>
                    appendAction({ actionType: "", parameters: {} })
                  }
                  disabled={!isCategorySelected}
                >
                  Add Action
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Schedule</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="schedule.interval"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Interval</FormLabel>
                        <FormDescription>
                          How often should this rule run to check conditions?
                        </FormDescription>
                        <FormControl>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select interval" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="daily">Daily</SelectItem>
                              <SelectItem value="weekly">Weekly</SelectItem>
                              <SelectItem value="biweekly">Biweekly</SelectItem>
                              <SelectItem value="monthly">Monthly</SelectItem>
                              <SelectItem value="bimonthly">
                                Bimonthly
                              </SelectItem>
                              <SelectItem value="quarterly">
                                Quarterly
                              </SelectItem>
                              <SelectItem value="semiannually">
                                Semiannually
                              </SelectItem>
                              <SelectItem value="annually">Annually</SelectItem>
                              <SelectItem value="on_deposit">
                                On Deposit
                              </SelectItem>
                              <SelectItem value="on_balance_threshold">
                                On Balance Threshold
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="schedule.nextRun"
                    rules={{ required: "Please select a target date." }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Start Date</FormLabel>
                        <FormDescription>
                          When should this rule start running?
                        </FormDescription>
                        <FormControl>
                          <Input
                            type="date"
                            {...field}
                            min={new Date().toISOString().split("T")[0]}
                            value={
                              field.value
                                ? new Date(field.value)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            onChange={(e) => {
                              const selectedDate = new Date(e.target.value);
                              field.onChange(selectedDate.toISOString());
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>

            <SheetFooter>
              <Button type="button" onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button type="submit" disabled={createLoading || updateLoading}>
                {rule ? "Update Rule" : "Create Rule"}
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

RuleForm.propTypes = {
  rule: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
};

export default RuleForm;
