import React, { useState } from "react";
import { Table } from "@tanstack/react-table";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { Cross2Icon } from "@radix-ui/react-icons";
import DataTableFacetedFilter from "./DataTableFacetedFilter";
import { DataTableViewOptions } from "./DataTableViewOptions";
import BulkActionMenu from "../BulkActionMenu";
import { Transaction } from "../../lib/transactionSchema";
import { useMemo } from "react";
import debounce from "lodash/debounce";
import useIsMobile from "../../hooks/useIsMobile";
import { cn } from "../../lib/utils";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  accountOptions: {
    id: string;
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  sliceOptions: {
    id: string;
    label: string;
    value: string;
  }[];
  categoryOptions: {
    id: string;
    label: string;
    value: string;
  }[];
  showingRecentTransactions: boolean;
  selectedRows: Transaction[];
  onBulkDelete?: (transactions: Transaction[]) => void;
  onBulkAssign?: (transactions: Transaction[], sliceId: string) => void;
  onBulkUnassign?: (transactions: Transaction[]) => void;
  onBulkChangeCategory?: (
    transactions: Transaction[],
    categoryId: string
  ) => void;
  onBulkChangeDescription?: (
    transactions: Transaction[],
    description: string
  ) => void;
  slices: { id: string; name: string }[];
  categories: { id: string; name: string }[];
  onFilterChange?: (filterType: string, values: any[] | string) => void;
  currentFilters: {
    accountIds: string[];
    sliceIds: string[];
    categoryIds: string[];
    searchTerm: string;
  };
}

function DataTableToolbar<TData>({
  table,
  accountOptions,
  sliceOptions,
  categoryOptions,
  showingRecentTransactions,
  selectedRows,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
  categories,
  onFilterChange,
  currentFilters,
}: DataTableToolbarProps<TData>) {
  const isFiltered =
    currentFilters.accountIds.length > 0 ||
    currentFilters.sliceIds.length > 0 ||
    currentFilters.categoryIds.length > 0 ||
    currentFilters.searchTerm !== "";

  const [searchValue, setSearchValue] = useState(currentFilters.searchTerm);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        onFilterChange?.("searchTerm", value);
      }, 300),
    [onFilterChange]
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const isMobile = useIsMobile();

  return (
    <div
      className={cn(
        isMobile
          ? "flex flex-col space-y-4"
          : "flex items-center justify-between"
      )}
    >
      <div
        className={cn(
          "flex items-center",
          isMobile ? "flex-col space-y-2 w-full" : "flex-1 space-x-2"
        )}
      >
        <Input
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => {
            handleSearchChange(e.target.value);
          }}
          className={cn("h-8", isMobile ? "w-full" : "w-[150px] lg:w-[250px]")}
        />
        <div
          className={cn(
            "flex items-center",
            isMobile ? "w-full overflow-x-auto py-2 space-x-2" : "space-x-2"
          )}
        >
          {table.getColumn("accountName") && (
            <DataTableFacetedFilter
              column={table.getColumn("accountName")}
              title="Account"
              options={accountOptions}
              onFilterChange={onFilterChange}
              currentValues={currentFilters.accountIds}
              filterType="accountIds"
            />
          )}
          {table.getColumn("assignedSliceName") && (
            <DataTableFacetedFilter
              column={table.getColumn("assignedSliceName")}
              title="Slice"
              options={sliceOptions}
              onFilterChange={onFilterChange}
              currentValues={currentFilters.sliceIds}
              filterType="sliceIds"
            />
          )}
          {table.getColumn("category") && (
            <DataTableFacetedFilter
              column={table.getColumn("category")}
              title="Category"
              options={categoryOptions}
              onFilterChange={onFilterChange}
              currentValues={currentFilters.categoryIds}
              filterType="categoryIds"
            />
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => {
                onFilterChange?.("accountIds", []);
                onFilterChange?.("sliceIds", []);
                onFilterChange?.("categoryIds", []);
                onFilterChange?.("searchTerm", "");
                setSearchValue("");
                table.resetColumnFilters();
              }}
              className="h-8 px-2 lg:px-3 whitespace-nowrap"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(
          "flex items-center",
          isMobile ? "w-full justify-between" : "space-x-2"
        )}
      >
        <BulkActionMenu
          selectedRows={selectedRows}
          onBulkDelete={onBulkDelete!}
          onBulkAssign={onBulkAssign!}
          onBulkUnassign={onBulkUnassign!}
          onBulkChangeCategory={onBulkChangeCategory!}
          onBulkChangeDescription={onBulkChangeDescription!}
          slices={slices}
          categories={categories}
        />
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}

export default DataTableToolbar;
