// app/javascript/components/SliceTransactionsTable.jsx
import React, { useState } from "react";
import DataTable from "./data-table";
import { getAccountOptions } from "../lib/utils";
import PropTypes from "prop-types";
import { sliceTransactionColumns } from "./data-table/sliceTransactionColumns";

const SliceTransactionsTable = ({
  sliceOptions,
  categories,
  accounts,
  categoryOptions,
  onRowClick,
  onEditTransaction,
  transactions,
  refetch,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
  pagination,
  onPageChange,
  onPerPageChange,
  onFilterChange,
  currentFilters,
}) => {
  const filteredColumns = sliceTransactionColumns.filter(
    (column) =>
      column.accessorKey !== "assignedSliceName" ||
      !sliceTransactionColumns.some(
        (col) => col.accessorKey === "assignedSliceName"
      )
  );

  return (
    <div className="h-max">
      <div className="overflow-x-auto h-full">
        <DataTable
          columns={filteredColumns}
          data={transactions}
          accountOptions={getAccountOptions(accounts)}
          sliceOptions={sliceOptions}
          refreshTransactions={refetch}
          showingRecentTransactions={false}
          categories={categories}
          hiddenColumns={["assignedSliceName"]}
          categoryOptions={categoryOptions}
          onRowClick={onRowClick}
          onEditTransaction={onEditTransaction}
          onBulkDelete={onBulkDelete}
          onBulkAssign={onBulkAssign}
          onBulkUnassign={onBulkUnassign}
          onBulkChangeCategory={onBulkChangeCategory}
          onBulkChangeDescription={onBulkChangeDescription}
          slices={slices}
          pagination={pagination}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          currentFilters={currentFilters}
          onFilterChange={onFilterChange}
          includeUnassigned={false}
        />
      </div>
    </div>
  );
};

SliceTransactionsTable.propTypes = {
  sliceOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  onBulkUnassign: PropTypes.func.isRequired,
  onBulkChangeCategory: PropTypes.func.isRequired,
  onBulkChangeDescription: PropTypes.func.isRequired,
  slices: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  currentFilters: PropTypes.object.isRequired,
};

export default SliceTransactionsTable;
