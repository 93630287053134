import { gql } from "@apollo/client";

const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    id
    accountId
    transactionId
    amount
    isoCurrencyCode
    category {
      id
      name
      icon
    }
    merchantName
    createdAt
    updatedAt
    transactionLocation
    pending
    pendingTransactionId
    transactionType
    transactionDate
    transactionAuthorizedDate
    envelopeId
    transactionName
    assignedSliceName
    assignedSliceType
    merchantLogoUrl
    accountName
    notes
    status
    isInflow
    balanceAfterTransaction
    balanceBeforeTransaction
  }
`;

const GET_TRANSACTIONS_FOR_USER = gql`
  query getTransactionsForUser(
    $page: Int
    $items: Int
    $accountIds: [String!]
    $sliceIds: [String!]
    $categoryIds: [String!]
    $searchTerm: String
  ) {
    transactionsForUser(
      page: $page
      items: $items
      accountIds: $accountIds
      sliceIds: $sliceIds
      categoryIds: $categoryIds
      searchTerm: $searchTerm
    ) {
      transactions {
        ...TransactionFields
      }
      pagination {
        currentPage
        totalPages
        totalCount
        pageSize
      }
    }
  }
  ${TRANSACTION_FIELDS}
`;

const GET_MERCHANTS = gql`
  query getMerchants {
    merchants {
      label
      value
    }
  }
`;

const GET_SAFE_TO_SPEND_BREAKDOWN = gql`
  query getSafeToSpendBreakdown {
    safeToSpendBreakdownForUser {
      availableBalance
      safeToSpend
      envelopeBalances
    }
  }
`;

const GET_MOST_RECENT_TRANSACTIONS = gql`
  query getMostRecentTransactions(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $page: Int
    $items: Int
    $accountIds: [String!]
    $sliceIds: [String!]
    $categoryIds: [String!]
    $searchTerm: String
  ) {
    mostRecentTransactionsForUser(
      startDate: $startDate
      endDate: $endDate
      page: $page
      items: $items
      accountIds: $accountIds
      sliceIds: $sliceIds
      categoryIds: $categoryIds
      searchTerm: $searchTerm
    ) {
      transactions {
        ...TransactionFields
      }
      pagination {
        currentPage
        totalPages
        totalCount
        pageSize
      }
    }
  }
  ${TRANSACTION_FIELDS}
`;

const GET_TRANSACTIONS_FOR_SLICE = gql`
  query getTransactionsForSlice(
    $sliceId: String!
    $page: Int
    $items: Int
    $accountIds: [String!]
    $categoryIds: [String!]
    $searchTerm: String
  ) {
    getTransactionsForSlice(
      sliceId: $sliceId
      page: $page
      items: $items
      accountIds: $accountIds
      categoryIds: $categoryIds
      searchTerm: $searchTerm
    ) {
      transactions {
        ...TransactionFields
      }
      pagination {
        currentPage
        totalPages
        totalCount
        pageSize
      }
    }
  }
  ${TRANSACTION_FIELDS}
`;

export {
  GET_TRANSACTIONS_FOR_USER,
  GET_MERCHANTS,
  GET_SAFE_TO_SPEND_BREAKDOWN,
  GET_MOST_RECENT_TRANSACTIONS,
  GET_TRANSACTIONS_FOR_SLICE,
};
