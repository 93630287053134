import React from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_TRANSACTION } from "../mutations/transactionMutations";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";
import CurrencyInput from "react-currency-input-field";
import toast from "react-hot-toast";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "./ui/Select";
import { Textarea } from "./ui/TextArea";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "./ui/Sheet";
import { RadioGroup, RadioGroupItem } from "./ui/RadioGroup";

const formSchema = z.object({
  transactionName: z.string().min(1, { message: "Description is required" }),
  amount: z.number().min(0.01, { message: "Please enter a valid amount" }),
  merchantName: z.string().optional(),
  categoryId: z.string().optional(),
  envelopeId: z.string().optional(),
  notes: z.string().optional(),
  isInflow: z.boolean().optional(),
});

interface TransactionFormProps {
  transaction: {
    id: string;
    amount: number;
    merchantName: string;
    category: {
      id: string;
      name: string;
      icon: string;
    };
    transactionName: string;
    envelopeId: string;
    notes?: string;
    isInflow: boolean;
  };
  categories: { id: string; name: string; icon: string }[];
  slices: { id: string; name: string }[];
  onClose: () => void;
  isOpen: boolean;
}

export const TransactionForm: React.FC<TransactionFormProps> = ({
  transaction,
  categories,
  onClose,
  slices,
  isOpen,
}) => {
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: transaction ? transaction.amount / 100 : 0,
      isInflow: transaction ? transaction.isInflow : false,
      merchantName: transaction?.merchantName || "",
      categoryId: transaction?.category?.id || "",
      transactionName: transaction?.transactionName || "",
      envelopeId: transaction?.envelopeId || "",
      notes: transaction?.notes || "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await updateTransaction({
        variables: {
          id: transaction.id,
          amount: Math.round(values.amount * 100),
          merchantName: values.merchantName,
          categoryId: values.categoryId,
          transactionName: values.transactionName,
          envelopeId:
            values.envelopeId === "unassigned" ? "" : values.envelopeId,
          notes: values.notes,
          isInflow: values.isInflow,
        },
      });
      toast.success("Transaction updated successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to update transaction");
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Edit Transaction</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="transactionName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      id="transactionName"
                      name="transactionName"
                      placeholder="Enter description"
                      defaultValue={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      id="amount"
                      name="amount"
                      placeholder="Enter amount"
                      defaultValue={field.value}
                      decimalsLimit={2}
                      onValueChange={(value) =>
                        field.onChange(parseFloat(value || "0"))
                      }
                      prefix="$"
                      className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isInflow"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Transaction Type</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value ? "inflow" : "outflow"}
                      className="flex space-x-4"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="inflow" id="inflow" />
                        <label htmlFor="inflow">Income</label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="outflow" id="outflow" />
                        <label htmlFor="outflow">Expense</label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="merchantName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Merchant Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="categoryId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Category</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={transaction.category?.id || field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {categories &&
                        categories.map((category) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="envelopeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Assigned Slice</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={
                      field.value === "" ? "unassigned" : field.value
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a slice" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="unassigned">Unassigned</SelectItem>
                      {slices &&
                        slices.map((slice) => (
                          <SelectItem key={slice.id} value={slice.id}>
                            {slice.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Add notes about this transaction"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <SheetFooter>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Update Transaction</Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};
