import React, { useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DataTableToolbar from "./DataTableToolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import DataTablePagination from "./DataTablePagination";
import { getCategoryOptions } from "../../lib/utils";
import { Transaction } from "../../lib/transactionSchema";
import { Separator } from "../ui/Separator";
import { Spinner } from "../ui/Spinner";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  accountOptions: {
    id: string;
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  sliceOptions: {
    id: string;
    label: string;
    value: string;
  }[];
  refreshTransactions: () => void;
  showingRecentTransactions: boolean;
  categories: { id: string; name: string }[];
  hiddenColumns?: string[];
  categoryOptions: {
    id: string;
    label: string;
    value: string;
  }[];
  onRowClick?: (transaction: Transaction) => void;
  onEditTransaction?: (transaction: Transaction) => void;
  onBulkDelete?: (transactions: Transaction[]) => void;
  onBulkAssign?: (transactions: Transaction[], sliceId: string) => void;
  onBulkUnassign?: (transactions: Transaction[]) => void;
  onBulkChangeCategory?: (
    transactions: Transaction[],
    categoryId: string
  ) => void;
  onBulkChangeDescription?: (
    transactions: Transaction[],
    description: string
  ) => void;
  slices: { id: string; name: string }[];
  pagination: {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
  };
  onPageChange: (page: number) => void;
  onPerPageChange: (pageSize: number) => void;
  onFilterChange: (filterType: string, values: any[] | string) => void;
  currentFilters: {
    accountIds: string[];
    sliceIds: string[];
    categoryIds: string[];
    searchTerm: string;
  };
  isLoading?: boolean;
  includeUnassigned?: boolean;
}

const DataTable = <TData, TValue>({
  columns,
  data,
  accountOptions,
  sliceOptions,
  refreshTransactions,
  showingRecentTransactions,
  categories,
  hiddenColumns,
  categoryOptions,
  onRowClick,
  onEditTransaction,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  pagination,
  onPageChange,
  onPerPageChange,
  onFilterChange,
  currentFilters,
  slices,
  isLoading,
  includeUnassigned = true,
}: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    hiddenColumns
      ? Object.fromEntries(hiddenColumns.map((col) => [col, false]))
      : {}
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const filteredData = React.useMemo(() => {
    if (!includeUnassigned) {
      return data.filter((item: any) => item.id !== "unassigned");
    }

    return data;
  }, [data, includeUnassigned]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageIndex: pagination.currentPage - 1,
        pageSize: pagination.pageSize,
      },
    },
    meta: {
      refreshTransactions: refreshTransactions as () => void,
      onEditTransaction: onEditTransaction as () => void,
      onRowClick: onRowClick as () => void,
      categories: categories,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    // getFacetedRowModel: getFacetedRowModel(),
    // getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
    pageCount: pagination.totalPages,
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.pageSize,
        });
        onPageChange(newState.pageIndex + 1);
        if (newState.pageSize !== pagination.pageSize) {
          onPerPageChange(newState.pageSize);
        }
      }
    },
  });

  const selectedRows = table
    .getFilteredSelectedRowModel()
    .rows.map((row) => row.original);

  return (
    <div className="space-y-4">
      <DataTableToolbar
        table={table}
        accountOptions={accountOptions}
        sliceOptions={sliceOptions}
        showingRecentTransactions={showingRecentTransactions}
        categoryOptions={getCategoryOptions(categories)}
        selectedRows={selectedRows as Transaction[]}
        onBulkDelete={onBulkDelete}
        onBulkAssign={onBulkAssign}
        onBulkUnassign={onBulkUnassign}
        onBulkChangeCategory={onBulkChangeCategory}
        onBulkChangeDescription={onBulkChangeDescription}
        slices={slices}
        categories={categories}
        onFilterChange={onFilterChange}
        currentFilters={currentFilters}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {(() => {
              // Loading state
              if (isLoading) {
                return (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <Spinner className="mx-auto" />
                    </TableCell>
                  </TableRow>
                );
              }

              // Empty state
              if (data.length === 0) {
                let message = "No transactions available";
                if (
                  currentFilters.searchTerm ||
                  currentFilters.accountIds.length > 0 ||
                  currentFilters.sliceIds.length > 0 ||
                  currentFilters.categoryIds.length > 0
                ) {
                  message = "No matching transactions found";
                }

                return (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      {message}
                    </TableCell>
                  </TableRow>
                );
              }

              // Data rows
              return table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onRowClick && onRowClick(row.original)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ));
            })()}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
};

export default DataTable;
