import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Overview from "../Overview";
import { defaultNavItems } from "./defaultNavItems";
import SlicesHome from "../SlicesHome";
import TransactionsHome from "../TransactionsHome";
import SettingsHome from "../SettingsHome";
import { Toaster } from "react-hot-toast";
import LogoutModal from "../LogoutModal";
import MenuContent from "./MenuContent";
import RulesHome from "../RulesHome";
import PlaidModal from "../PlaidModal";
import { useQuery } from "@apollo/client";
import {
  GET_USER_PLAID_STATUS,
  GET_ACCOUNTS_FOR_USER,
} from "../../queries/plaidQueries";
import { GET_CATEGORIES } from "../../queries/categoryQueries";
import { GET_SLICES_FOR_USER } from "../../queries/sliceQueries";
import { Spinner } from "../ui/Spinner";
import { Mail, Menu, X } from "lucide-react"; // Import the Mail icon
import { Button } from "../ui/Button";

const Layout = () => {
  const [currentNavItem, setCurrentNavItem] = useState(defaultNavItems[0].id);
  const [plaidModalOpen, setPlaidModalOpen] = useState(false);
  const [needPlaidReconnection, setNeedPlaidReconnection] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });

  const { data: plaidStatusData, refetch: refetchPlaidStatus } = useQuery(
    GET_USER_PLAID_STATUS
  );

  const { data: accountsData, loading: loadingAccounts } = useQuery(
    GET_ACCOUNTS_FOR_USER
  );
  const { data: categoriesData, loading: loadingCategories } =
    useQuery(GET_CATEGORIES);
  const { data: slicesData, loading: loadingSlices } =
    useQuery(GET_SLICES_FOR_USER);

  const handleDateRangeChange = useCallback((newDateRange) => {
    setDateRange((prevDateRange) => {
      if (
        prevDateRange.startDate.getTime() !==
          newDateRange.startDate.getTime() ||
        prevDateRange.endDate.getTime() !== newDateRange.endDate.getTime()
      ) {
        return {
          startDate: new Date(newDateRange.startDate),
          endDate: new Date(newDateRange.endDate),
        };
      }
      return prevDateRange;
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (plaidStatusData) {
      const { hasPlaidDetails, needPlaidReconnection } = plaidStatusData.user;
      if (!hasPlaidDetails) {
        setPlaidModalOpen(true);
      }
      if (needPlaidReconnection) {
        setNeedPlaidReconnection(true);
      }
    }
  }, [plaidStatusData]);

  const memoizedDateRange = useMemo(
    () => ({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }),
    [dateRange.startDate.getTime(), dateRange.endDate.getTime()]
  );

  useEffect(() => {
    if (needPlaidReconnection) {
      setPlaidModalOpen(true);
    }
  }, [needPlaidReconnection]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setCurrentNavItem("overview");
        break;
      case "/slices":
        setCurrentNavItem("slices");
        break;
      case "/transactions":
        setCurrentNavItem("transactions");
        break;
      case "/rules":
        setCurrentNavItem("rules");
        break;
      case "/settings":
        setCurrentNavItem("settings");
        break;
    }
    setIsMenuOpen(false);
  }, [location.pathname]);

  if (loadingAccounts || loadingCategories || loadingSlices) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex">
      <Toaster position="bottom-right" reverseOrder={false} />
      <PlaidModal
        modalOpen={plaidModalOpen}
        setModalOpen={setPlaidModalOpen}
        needPlaidReconnection={needPlaidReconnection}
        setNeedPlaidReconnection={setNeedPlaidReconnection}
        refetchPlaidStatus={refetchPlaidStatus}
      />
      <button
        className="lg:hidden fixed top-4 right-4 z-50 p-2 bg-white rounded-md shadow-sm"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
      {isMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-40 bg-white">
          <div className="h-full flex grow flex-col gap-y-5 overflow-y-auto px-6 pt-16">
            <MenuContent setCurrentNavItem={setCurrentNavItem} />
            <div className="mt-auto mb-4">
              <Button
                variant="ghost"
                className="rounded-md text-center w-full p-2 hover:bg-gray-100 hover:text-gray-900"
                onClick={() =>
                  (window.location.href =
                    "mailto:contact@trycakebudget.com?subject=Cake Budget Support: [YOUR REQUEST HERE]")
                }
              >
                <Mail className="mr-2 h-4 w-4" />
                Email Support
              </Button>
              <LogoutModal />
            </div>
          </div>
        </div>
      )}
      {/* Responsive sidebar */}
      <div className="hidden lg:block lg:fixed lg:inset-y-0 lg:z-50 lg:w-72">
        <div className="flex h-full flex-col gap-y-5 overflow-y-auto border-r border-cool-grey-200 bg-white px-6">
          <MenuContent setCurrentNavItem={setCurrentNavItem} />
          <div className="mt-auto hidden lg:block mb-4">
            <Button
              variant="ghost"
              className="rounded-md text-center w-full p-2 hover:bg-gray-100 hover:text-gray-900"
              onClick={() =>
                (window.location.href =
                  "mailto:contact@trycakebudget.com?subject=Cake Budget Support: [YOUR REQUEST HERE]")
              }
            >
              <Mail className="mr-2 h-4 w-4" />
              Email Support
            </Button>
            <LogoutModal />
          </div>
        </div>
      </div>

      <main className="lg:pl-72 h-full w-full bg-background-white overflow-hidden">
        {currentNavItem === "overview" ? (
          loadingAccounts ||
          !plaidStatusData ||
          !accountsData ||
          !categoriesData ||
          !slicesData ? (
            <div>Loading...</div>
          ) : (
            <Overview
              plaidStatusData={plaidStatusData}
              refetchPlaidStatus={refetchPlaidStatus}
              accountsData={accountsData}
              categoriesData={categoriesData}
              slicesData={slicesData}
              dateRange={memoizedDateRange}
              onDateRangeChange={handleDateRangeChange}
            />
          )
        ) : null}
        {currentNavItem === "slices" && <SlicesHome />}
        {currentNavItem === "transactions" && (
          <TransactionsHome
            setPlaidModalOpen={setPlaidModalOpen}
            setNeedPlaidReconnection={setNeedPlaidReconnection}
          />
        )}
        {currentNavItem === "rules" && <RulesHome />}
        {currentNavItem === "settings" && <SettingsHome />}
      </main>
    </div>
  );
};

export default Layout;
