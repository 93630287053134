import { useApolloClient, useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { VALIDATE_SESSION_QUERY } from "../queries/sessionQueries";
import PropTypes from "prop-types";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isSessionValid, setIsSessionValid] = useState(true);
  const [skipInitialValidation, setSkipInitialValidation] = useState(true);

  const [validateSession] = useLazyQuery(VALIDATE_SESSION_QUERY, {
    onCompleted: (data) => {
      if (!data.validateSession.valid) {
        setUser(null);
        localStorage.removeItem("user");
        setIsSessionValid(false);
        window.dispatchEvent(new Event("session-invalid"));
      } else {
        setIsSessionValid(true);
      }
    },
    fetchPolicy: "network-only", // Ensures it always goes to the network instead of cache
  });

  const client = useApolloClient();

  useEffect(() => {
    if (!skipInitialValidation) {
      validateSession();
    }
  }, [user, skipInitialValidation]);

  // Start periodic validaton after inital login
  useEffect(() => {
    const interval = setInterval(() => {
      if (user && !skipInitialValidation) {
        validateSession();
      }
    }, 180000);

    return () => clearInterval(interval);
  }, [user, skipInitialValidation]);

  const updateUser = (userData) => {
    return new Promise((resolve) => {
      setUser(userData);
      try {
        localStorage.setItem("user", JSON.stringify(userData));
      } catch (error) {
        console.error("LocalStorage error:", error);
      }
      setSkipInitialValidation(true);
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const signOut = () => {
    setUser(null);
    setIsSessionValid(false);
    localStorage.removeItem("user");
    setSkipInitialValidation(true);
    client.resetStore();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        signOut,
        isSessionValid,
        validateSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const userContext = useContext(UserContext);
  return userContext;
};
