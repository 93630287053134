import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./Select";
import { DatePickerWithRange } from "./DatePickerWithRange";
import { format } from "date-fns";

interface DateRangeSelectorProps {
  onDateRangeChange: (
    dateRange: { startDate: Date; endDate: Date },
    preset: string
  ) => void;
  initialDateRange: { startDate: Date; endDate: Date };
  selectedPreset: string;
  setSelectedPreset: (value: string) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  onDateRangeChange,
  initialDateRange,
  selectedPreset,
  setSelectedPreset,
}) => {
  const [customRange, setCustomRange] = useState<{ from: Date; to: Date }>({
    from: initialDateRange.startDate,
    to: initialDateRange.endDate,
  });

  const calculatedDateRange = useMemo(() => {
    if (selectedPreset === "custom") {
      return customRange;
    }
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - parseInt(selectedPreset));
    startDate.setHours(0, 0, 0, 0);
    return { from: startDate, to: endDate };
  }, [selectedPreset, customRange]);

  useEffect(() => {
    if (calculatedDateRange) {
      const newDateRange = {
        startDate: calculatedDateRange.from,
        endDate: calculatedDateRange.to,
      };
      onDateRangeChange(newDateRange, selectedPreset);
    }
  }, [calculatedDateRange, onDateRangeChange, selectedPreset]);

  const handlePresetChange = useCallback(
    (value: string) => {
      setSelectedPreset(value);
    },
    [setSelectedPreset]
  );

  const handleCustomRangeChange = useCallback(
    (range: { from: Date; to: Date } | undefined) => {
      if (range && range.from && range.to) {
        setCustomRange(range);
        setSelectedPreset("custom");
      }
    },
    [setSelectedPreset]
  );

  const presets = [
    { label: "Last 7 days", value: "7" },
    { label: "Last 14 days", value: "14" },
    { label: "Last 30 days", value: "30" },
    { label: "Last 60 days", value: "60" },
    { label: "Last 90 days", value: "90" },
    { label: "Last 180 days", value: "180" },
    { label: "Last 365 days", value: "365" },
  ];

  const formatDateRange = (from: Date, to: Date) => {
    return `${format(from, "MMM d, yyyy")} - ${format(to, "MMM d, yyyy")}`;
  };

  return (
    <div className="flex items-center space-x-4">
      <Select value={selectedPreset} onValueChange={handlePresetChange}>
        <SelectTrigger className="w-[300px]">
          <SelectValue placeholder="Select time range" />
        </SelectTrigger>
        <SelectContent>
          {presets.map((preset) => (
            <SelectItem key={preset.value} value={preset.value}>
              {preset.label}
            </SelectItem>
          ))}
          {/* <SelectItem value="custom">Custom Range</SelectItem> */}
        </SelectContent>
      </Select>
      {/* {selectedPreset === "custom" && (
        <DatePickerWithRange
          className="w-[300px]"
          selected={customRange}
          onSelect={handleCustomRangeChange}
        />
      )} */}
    </div>
  );
};

export default DateRangeSelector;
