import React from "react";
import { SliceForm } from "./SliceForm";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "./ui/Sheet";
import PropTypes from "prop-types";

export const SliceFormSheet = ({
  isOpen,
  onClose,
  setSelectedSlice,
  isEditMode,
  selectedSlice,
  accounts,
}) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="w-[400px] sm:w-[540px] overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{isEditMode ? "Edit Slice" : "Create Slice"}</SheetTitle>
          <SheetDescription>
            {isEditMode
              ? "Make changes to your slice here."
              : "Add a new slice to your budget."}
          </SheetDescription>
        </SheetHeader>
        <div className="mt-6">
          <SliceForm
            setOpen={onClose}
            setSelectedSlice={setSelectedSlice}
            isEditMode={isEditMode}
            selectedSlice={selectedSlice}
            accounts={accounts}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

SliceFormSheet.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setSelectedSlice: PropTypes.func,
  isEditMode: PropTypes.bool,
  selectedSlice: PropTypes.object,
  accounts: PropTypes.array,
};
