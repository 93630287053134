import React, { useState } from "react";
import { Button } from "./ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "./ui/Dropdown";
import { Transaction } from "../lib/transactionSchema";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/Dialog";
import { Input } from "./ui/Input";
import ConfirmationModal from "./ConfirmationModal";

interface BulkActionMenuProps {
  selectedRows: Transaction[];
  onBulkDelete: (transactions: Transaction[]) => void;
  onBulkAssign: (transactions: Transaction[], sliceId: string) => void;
  onBulkUnassign: (transactions: Transaction[]) => void;
  onBulkChangeCategory: (
    transactions: Transaction[],
    categoryId: string
  ) => void;
  onBulkChangeDescription: (
    transactions: Transaction[],
    description: string
  ) => void;
  slices: { id: string; name: string }[];
  categories: { id: string; name: string }[];
}

const BulkActionMenu: React.FC<BulkActionMenuProps> = ({
  selectedRows,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
  categories,
}) => {
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);

  if (selectedRows.length === 0) return null;

  const handleChangeDescription = () => {
    onBulkChangeDescription(selectedRows, newDescription);
    setIsDescriptionModalOpen(false);
    setNewDescription("");
  };

  const handleDelete = () => {
    onBulkDelete(selectedRows);
    setIsDeleteModalOpen(false);
  };

  const handleUnassign = () => {
    onBulkUnassign(selectedRows);
    setIsUnassignModalOpen(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button variant="outline">
            Bulk Actions ({selectedRows.length})
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={() => setIsDeleteModalOpen(true)}>
            Delete Selected
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => setIsUnassignModalOpen(true)}>
            Unassign from Slice
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Assign to Slice</DropdownMenuSubTrigger>
            <DropdownMenuSubContent className="max-h-80 overflow-y-auto">
              {slices.map((slice) => (
                <DropdownMenuItem
                  key={slice.id}
                  onSelect={() => onBulkAssign(selectedRows, slice.id)}
                >
                  {slice.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Change Category</DropdownMenuSubTrigger>
            <DropdownMenuSubContent className="max-h-80 overflow-y-auto">
              {categories.map((category) => (
                <DropdownMenuItem
                  key={category.id}
                  onSelect={() =>
                    onBulkChangeCategory(selectedRows, category.id)
                  }
                >
                  {category.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuItem onSelect={() => setIsDescriptionModalOpen(true)}>
            Change Description
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={isDescriptionModalOpen}
        onOpenChange={setIsDescriptionModalOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Description</DialogTitle>
          </DialogHeader>
          <Input
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            placeholder="Enter new description"
          />
          <Button onClick={handleChangeDescription}>Apply</Button>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        title="Delete Transactions"
        description={`Are you sure you want to delete ${selectedRows.length} transaction(s)? This action cannot be undone.`}
        confirmText="Delete"
      />

      <ConfirmationModal
        isOpen={isUnassignModalOpen}
        onClose={() => setIsUnassignModalOpen(false)}
        onConfirm={handleUnassign}
        title="Unassign Transactions"
        description={`Are you sure you want to unassign ${selectedRows.length} transaction(s) from their current slice?`}
        confirmText="Unassign"
      />
    </>
  );
};

export default BulkActionMenu;
