import { gql } from "@apollo/client";

const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction(
    $id: ID!
    $envelopeId: ID
    $merchantName: String
    $transactionName: String
    $categoryId: ID
    $amount: Int
    $notes: String
  ) {
    updateTransaction(
      input: {
        id: $id
        envelopeId: $envelopeId
        merchantName: $merchantName
        transactionName: $transactionName
        categoryId: $categoryId
        amount: $amount
        notes: $notes
      }
    ) {
      transaction {
        id
        amount
        merchantName
        category {
          id
          name
          icon
        }
        notes
      }
      errors
    }
  }
`;

const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($id: ID!) {
    deleteTransaction(input: { id: $id }) {
      success
      errors
    }
  }
`;

const BULK_DELETE_TRANSACTIONS = gql`
  mutation BulkDeleteTransactions($ids: [ID!]!) {
    bulkDeleteTransactions(input: { ids: $ids }) {
      success
      errors
    }
  }
`;

const BULK_ASSIGN_TRANSACTIONS_TO_SLICE = gql`
  mutation BulkAssignTransactionsToSlice($ids: [ID!]!, $sliceId: ID!) {
    bulkAssignTransactionsToSlice(input: { ids: $ids, sliceId: $sliceId }) {
      success
      errors
    }
  }
`;

const BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE = gql`
  mutation BulkUnassignTransactionsFromSlice($ids: [ID!]!) {
    bulkUnassignTransactionsFromSlice(input: { ids: $ids }) {
      success
      errors
    }
  }
`;

const BULK_CHANGE_TRANSACTION_CATEGORY = gql`
  mutation BulkChangeTransactionCategory($ids: [ID!]!, $categoryId: ID!) {
    bulkChangeTransactionCategory(
      input: { ids: $ids, categoryId: $categoryId }
    ) {
      success
      errors
    }
  }
`;

const BULK_CHANGE_TRANSACTION_DESCRIPTION = gql`
  mutation BulkChangeTransactionDescription(
    $ids: [ID!]!
    $description: String!
  ) {
    bulkChangeTransactionDescription(
      input: { ids: $ids, description: $description }
    ) {
      success
      errors
    }
  }
`;

export {
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
};
